import { Link } from 'gatsby'
import React from "react"
// import { useReduceMotion } from "react-reduce-motion";
import { useScrollAnimation } from "../components/useScrollAnimation";
import { motion, useTransform } from "framer-motion";
import Layout from '../components/Layout'
import Seo from "../components/seo"
import BannerMp4 from '../videos/home-xmas.mp4'
import BannerWebm from '../videos/home-xmas.webm'
import BannerOgv from '../videos/home.ogv'
import BannerCover from '../videos/video_cover_home.jpg'
import PhoneMp4 from '../videos/phone.mp4'
import PhoneWebm from '../videos/phone.webm'
import PhoneOgv from '../videos/phone.ogv'
import PhoneCover from '../videos/video_cover_phone.jpg'
import { StaticImage } from "gatsby-plugin-image";
// import FeaturedProjects from '../components/FeaturedProjects'
import TechLogos from '../components/TechLogos'

import Icon from '@mdi/react'
import { mdiChevronDoubleDown, mdiArrowRight } from '@mdi/js'


export default function Home({ data }) {


  // const prefersReducedMotion = useReduceMotion();
  const prefersReducedMotion = false; //react-reduce-motion fails at build time due to window

  const [bannerRef, bannerScroll] = useScrollAnimation();
  const bannerScale = useTransform(bannerScroll, [0, 0.5, 1], prefersReducedMotion ? [1, 1, 1] : [1, 1, 1.2]);

  const [phoneRef, phoneScroll] = useScrollAnimation();
  const phoneBgScale = useTransform(phoneScroll, [0, 0.5], prefersReducedMotion ? [1.2, 1] : [1.4, 1]);
  const phoneTranslate = useTransform(phoneScroll, [0, 0.4], prefersReducedMotion ? ['0%', '0%'] : ['50%', '0%']);
  const textTranslate = useTransform(phoneScroll, [0, 1], prefersReducedMotion ? ['0%', '0%'] : ['-90%', '0%']);


  const [teamRef, teamScroll] = useScrollAnimation();
  const teamScale = useTransform(teamScroll, [0, 1], prefersReducedMotion ? [1, 1.1] : [1.2, 1]);

  return (
    <Layout>

    <Seo
        title='Custom software development | Progressive web applications - Webfox'
        description='We design, develop and support bespoke software and progressive web applications.'
    />

      {/* <div style={{ position: "fixed", top: "10px", backgroundColor: "red" }}>  */}
      {/* {`${phoneScroll.get().toFixed(2)}% (phoneBgScale ${phoneBgScale.get()}) (phoneTranslate ${phoneTranslate.get()}) (textTranslate ${textTranslate.get()})`} */}
      {/* {`${bannerScroll.get().toFixed(2)}% (bannerScale ${bannerScale.get()})`} */}
      {/* </div> */}

      <div ref={bannerRef}>
        <section className="layer intro intro-home dark">

          <motion.video animate={{ scale: bannerScale.get() }} id="background-video" autoPlay loop muted playsInline poster={BannerCover}>
            <source src={BannerWebm} type='video/webm' />
            <source src={BannerMp4} type='video/mp4' />
            <source src={BannerOgv} type='video/ogg' />
          </motion.video>


          <div className="inner">
            <div className="copy">
              <h1>We use new technology to solve problems, and give your business a digital edge.</h1>
              <div className="button-group">
                <Link to="what-we-do" className="button icon-right">See How <Icon path={mdiArrowRight} size={1.3} /></Link>
              </div>
            </div>
            <a href="#after-intro" className="intro-anchor"><Icon path={mdiChevronDoubleDown} size={1.3} />&#8203;</a>


          </div>

        </section>
      </div>


      <section className="layer double-text transparent" id="after-intro">
        <div className="inner">
          <div className="grid col-2 align-top">
            <div className="col">
              <h2>We design, develop, and support bespoke software, progressive web apps, and AI-driven solutions.</h2>
            </div>
            <div className="col">
              <p>But we don’t just build platforms, we design human-centered digital solutions. We blend simple, intuitive interfaces with complex back-end engineering to create custom digital tools that support and advance your business goals. These digital tools are secure, future-proofed and reliable, leveraging world-class technologies to help your organisation redefine how it does business.</p>
            </div>
          </div>
        </div>
      </section>


      <section className="layer experience transparent" data-section="default">
        <div className="inner">

          <div className="grid col-2">
            <div className="col">
              <div ref={phoneRef} className="background-zoom-image">
                <motion.div animate={{ scale: phoneBgScale.get() }}>
                  <StaticImage
                    src="../images/home-phonebg.jpg"
                    alt="TODO"
                    style={{ minWidth: "100%", minHeight: "100%", maxWidth: "100%" }}
                  />
                </motion.div>
              </div>

              <div className="phone">
                <motion.div animate={{ translateY: phoneTranslate.get() }}>
                  <div className="phone-skin"></div>
                  <video autoPlay loop muted poster={PhoneCover}>
                    <source src={PhoneWebm} type='video/webm' />
                    <source src={PhoneMp4} type='video/mp4' />
                    <source src={PhoneOgv} type='video/ogv' />
                  </video>
                </motion.div>
              </div>

            </div>
            <div className="col">
              <motion.div animate={{ translateY: textTranslate.get() }}>
                <h3>Create meaningful cross-digital experiences, improve business performance and connect with your customers where they want to engage.</h3>
                <div className="button-group">
                  <Link to="what-we-do" className="button icon-right">What We Do <Icon path={mdiArrowRight} size={1.3} /></Link>
                </div>
              </motion.div>
            </div>
          </div>

        </div>
      </section>



      <section className="layer double-text team-pic black-bg dark">
        <div className="inner">
          <div ref={teamRef} className="background-zoom-image">
            <motion.div animate={{ scale: teamScale.get() }}>

              <StaticImage
                src="../images/team/webfox-team-2022.jpg"
                alt="TODO"
                style={{ minWidth: "100%", minHeight: "100%", maxWidth: "100%" }}
              />
            </motion.div>
          </div>
          <div className="grid col-2 align-top">
            <div className="col">
              <h2>We’re a senior team, by design</h2>
            </div>
            <div className="col">
              <p>That means you get access to expertise without unnecessary hierarchy. The team you talk to, are the team behind the code. We’re tightly connected to the outcomes of every project, and our agility as a team that can scale up or scale down allows us to act as a true partner in driving your business forward.</p>
              <div className="button-group">
                <Link to="who-we-are" className="button icon-right">Who We Are <Icon path={mdiArrowRight} size={1.3} /></Link>
              </div>
            </div>
          </div>

        </div>
      </section>



      {/* <section className="layer featured-projects transparent">
        <div className="inner">
          <h4>Featured Projects</h4>

          <FeaturedProjects />

          <div className="double-text">
            <div className="grid col-2 align-top">
              <div className="col">
                <h2>Build, test, iterate. Repeat.</h2>
              </div>
              <div className="col">
                <p>We have a transparent way of working, following an incremental, agile framework that keeps you closely involved and up-to-date as we continue to problem solve, innovate and refine in order to produce the most meaningful digital solution for your business.</p>
                <div className="button-group">
                  <Link to="work-weve-done" className="button icon-right">See Our Work <Icon path={mdiArrowRight} size={1.3} /></Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <TechLogos />

    </Layout>
  );
}

// export const query = graphql`query MyQuery {
//   file(relativePath: {eq: "home2.jpg"}) {
//     childImageSharp {
//       gatsbyImageData(layout: FULL_WIDTH)
//     }
//   }
// }
// `
